/* Theme */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "assets/layout/styles/theme/lara-light-blue/theme.css";

/* Core CSS */
@import "primeng/resources/primeng.min.css";
@import "primeng/resources/primeng.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";

/* Layout & Demo */
@import "assets/layout/styles/layout/layout.scss";
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

/* Custom Global Styles */

a {
  text-decoration: none !important;
}

.layout-topbar {
  background: #0b2333 !important;
}

.layout-topbar .layout-topbar-button {
  color: white !important;
}

.password-modal-input .p-password input {
  width: 100%;
}

.bank-cards .card-bank .p-card-body {
  background: #00000005;
  border: 8px solid white;
  border-radius: 6px;
}

.bank-cards .active .p-card-body {
  background: aliceblue;
  border: 8px solid white;
  border-radius: 6px;
}

// .money-wallet{
.money-wallet .p-button .p-button-icon-left {
  margin-left: 10px !important;
}

.money-wallet .p-button .p-button-label {
  margin-right: 10px;
}

// }

.form-input .p-dropdown,
.form-input .p-calendar {
  width: 225px;
}

.p-dialog-mask.p-component-overlay {
  z-index: 1000 !important;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}

input.ng-invalid.ng-touched {
  border-color: red !important;
}

legend {
  float: unset !important;
  width: auto !important;
  padding: 0;
  margin-bottom: 0px;
  font-size: 14px !important;
  line-height: inherit;
}

fieldset {
  min-width: auto !important;
  padding: revert !important;
  margin: 0;
  border: 0;
}

.customers .btn-cont .p-button {
  width: 95px;
}


.label-required:after {
  content: " *";
  font-size: 1.1rem;
  color: red;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

label {
  font-size: 14px;
  font-weight: 500;
}

:host ::ng-deep {

  .p-datatable-thead>tr>th,
  .p-datatable-tbody td {
    text-align: center !important;
  }
}

.p-datatable-thead>tr>th,
.p-datatable-tbody td {
  text-align: center !important;
}

.clickable {
  cursor: pointer;
}

.text-limit {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.p-button-xs {
  width: 1.8rem !important;
  height: 1.8rem !important;
  padding: 0.25rem !important;
}

.p-button-sm {
  width: 2rem !important;
  height: 2rem !important;
  padding: 0.33rem !important;
}

.p-inputtext{
  width: 100%;
}
textarea.ng-invalid.ng-touched {
  border-color: red!important;
}

.ng-submitted{
  textarea.ng-invalid {
    border-color: red!important;
  }
  input.ng-invalid {
    border-color: red !important;
  }
}

::ng-deep {
  .form-floating > label {
    /* your styles here */
  }
}
:host ::ng-deep {
  .p-autocomplete .p-component .p-inputwrapper {
    display: block;
  }
}
:host ::ng-deep {
  .p-blockui.p-component-overlay{
    z-index: 1000 !important;
  }
}
.p-blockui.p-component-overlay{
  z-index: 1000 !important;
}

.banner-menu, .optimized-menu{
  ul.p-menu-list{
    padding: 0.25rem !important;
    margin-bottom: unset !important;
  }
}

.p-confirm-dialog{
  .p-dialog-title{
    font-weight: 600 !important;
  }

  .p-confirm-dialog-message{
    font-size: 1.075rem !important;
    font-weight: 500 !important;
  }
}

.p-selectbutton.p-buttonset {
  .p-ripple.p-element.p-button {
    &:first-child {
      // Styles for the first button
      border-end-end-radius: 0px !important;
      border-start-end-radius: 0px !important;
    }
    
    &:nth-child(2) {
      // Styles for the second button
      border-end-start-radius: 0px !important;
      border-start-start-radius: 0px !important;
    }
  }
}

.p-calendar.p-calendar-w-btn{
  input {
    border-end-end-radius: 0px !important;
    border-start-end-radius: 0px !important;
  }
  button {
    // Styles for the second button
    border-end-start-radius: 0px !important;
    border-start-start-radius: 0px !important;
  }
}